.App {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
}
body {
  height: 100vh;
  overflow: hidden;
}
/* size 67% */
h1 {
  font-size: 3.35em;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.01em;
}

p {
  font-size: 1.34em;
  font-weight: 500;
  line-height: 2.63;
  letter-spacing: 0.033em;
  color: #2d2d2d;
  margin-block-start: 0;
  margin-block-end: 0;
}
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4% 5.4%;
}
.about-wrapper .navbar {
  width: 100%;
  align-self: flex-start;
  box-sizing: border-box;
}
.navbar .logo-rt {
  font-weight: 600;
  font-size: 1.675em;
  letter-spacing: 0.12em;
}
.about-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: scroll;
}
.about-content {
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: row;
  text-align: left;
  padding: 4% 5.4%;
}
.about-content img {
  width: 100%;
}
.about-content div:first-child p,
.about-content div:first-child li {
  font-size: 1.125em;
  max-width: 600px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: 0.6px;
  color: #2d2d2d;
}
.about-content div:first-child ul {
  padding-inline-start: 3%;
}
.about-content div:first-child h1 {
  font-weight: 500;
  margin-block-end: 1.4em;
  margin-block-start: 2em;
}
.about-content div:first-child h1:first-child {
  margin-block-start: 0;
}

.navbar .logo-ui {
  font-weight: 600;
  line-height: 1.5;
  font-size: 1.005em;
  color: #ff1828;
  letter-spacing: 0.027em;
}
.navbar .logo-ux {
  font-weight: 600;
  line-height: 1.5;
  font-size: 1.005em;
  color: #81929b;
  letter-spacing: 0.027em;
}

.navbar-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main {
  display: flex;
  flex-direction: column;
  background-color: white;
  /* height: 100%; */
  width: 100%;
  overflow: scroll;
  height: 100vh;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}
.dots-wrapper {
  position: fixed;
  /* z-index: 100; */
  flex-direction: column;
  right: 5.4%;
  top: calc(50% - 40px);
  width: 16px !important;
  height: 100px !important;
}
.dot-holder {
  margin-bottom: 10px;
}
.react-carousel-dots-dot {
  border: 2px solid black !important;
}
.ff,
.ss,
.tt {
  scroll-snap-align: start;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column;
  height: 90vh;
  width: 100%;
  padding-bottom: 5%;
  scroll-snap-align: start;
}
.portfolio-header {
  display: flex;
  flex-direction: column;
}
.header .space-ui {
  margin-left: 10px;
}
.view-portfolio {
  margin-top: 5%;
  justify-self: flex-end;
}
.view-portfolio img {
  cursor: pointer;
}
.navbar-links {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.navbar-links a {
  color: black;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.116em;
}
.navbar a {
  text-decoration: none;
  font-weight: 500;
}
.navbar p {
  margin: 0;
  line-height: 1;
  color: black;
}
.navbar-links a {
  margin-left: 6.2vw;
}

.project {
  height: 100vh;
  width: 100vw;
  display: flex;
}
.hover {
  font-size: 36px;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  transition: all 0.1s ease-in-out;
  position: relative;
}
.hover:before,
.hover:after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0px;
  height: 2px;
  margin: 5px 0 0;
  transition: all 0.1s ease-in-out;
  transition-duration: 0.35s;
  /* opacity: 0; */
  background-color: #fff056;
}
.ss .hover:before,
.ss .hover:after {
  background-color: #702bff;
}
.ff .hover:before,
.ff .hover:after {
  background-color: #ff4b6a;
}
.hover.hover-1:before,
.hover.hover-1:after {
  left: 0;
}
.hover-line:hover {
  cursor: pointer;
}
.hover-line:hover .hover:before,
.hover-line:hover .hover:after {
  width: 100%;
  /* opacity: 1; */
}

.project h1 {
  font-size: 2.8em;
  text-transform: uppercase;
  white-space: pre;
  text-align: left;
}
.project-image-wrapper {
  pointer-events: none;
  position: absolute;
  width: 60%;
  height: calc(100vw / 4.5);
  left: -54%;
  z-index: 100;
}
.reverse .project-image-wrapper {
  right: -58%;
  width: 17vw;
  left: auto;
  height: calc(100vw / 2.86);
}
.project-image-wrapper img:first-child {
  /* z-index: 100; */
}

.project-image {
  width: 100%;
  height: 100%;
  position: absolute;
  /* top: 100px; */
  right: 0;
}
.circle {
  visibility: hidden;
  width: 75%;
  top: -30%;
  left: -14%;
  position: absolute;
  border-radius: 50%;
  height: calc(100vw / 4.2);
  background-color: #ffa48a;
}
/* .circle.show-ui {
  visibility: show;
} */

.deco-travel {
  width: 95%;
  top: -10%;
  left: 38%;
  position: absolute;
  height: calc(100vw / 4.2);
}
.deco-travel img {
  width: 100%;
  visibility: hidden;
}
.project-image-wrapper.smart img {
  transform: rotate(-15deg);
}
.show-ui .deco-travel img {
  visibility: visible;
}
/* .project-image.show-ui {
  z-index: 101;
} */

.project-image-wrapper.coffee {
  width: 17vw;
  height: calc(100vw / 2.86);
  left: -50%;
}
.project-image-wrapper img:first-of-type {
  visibility: hidden;
}
.show-ui .project-image-wrapper img:first-of-type {
  visibility: visible;
  z-index: 101;
}
.show-ui .circle {
  visibility: visible;
}
.project-peak,
.project-description {
  width: 50%;
  height: 100%;
}
.project-peak {
  position: relative;
  z-index: 2;
  padding-left: 10%;
  width: 39%;
}
.project-description {
  position: relative;
  align-items: flex-start;
  padding-left: 6.3%;
}
.project-description p {
  padding-top: 3%;
  font-size: 1em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #383131;
  max-width: 272px;
  text-align: left;
}
.project-peak-bg {
  cursor: pointer;
}
.project-peak-bg,
.project-peak-bg-ux {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.project-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.project-description .project-type {
  padding-top: 5%;
  font-size: 0.9em;
  color: #7d7777;
  line-height: 3.7;
}
.toggle-project-wrapper-header {
  display: flex;
  backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  animation-name: wiggle;
  -o-animation-name: wiggle;
  -ms-animation-name: wiggle;
  -moz-animation-name: wiggle;
  -webkit-animation-name: wiggle;
  animation-duration: 4s;
  -o-animation-duration: 4s;
  -ms-animation-duration: 4s;
  -moz-animation-duration: 4s;
  -webkit-animation-duration: 4s;
  animation-timing-function: linear;
  -o-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-play-state: running;
  -o-animation-play-state: running;
  -ms-animation-play-state: running;
  -moz-animation-play-state: running;
  -webkit-animation-play-state: running;
}
.trymebitch {
  cursor: pointer;
  top: 48.9vw;
  left: 39vw;
  position: absolute;
  width: 7vw;
  backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  animation-name: wiggle;
  -o-animation-name: wiggle;
  -ms-animation-name: wiggle;
  -moz-animation-name: wiggle;
  -webkit-animation-name: wiggle;
  animation-duration: 4s;
  -o-animation-duration: 4s;
  -ms-animation-duration: 4s;
  -moz-animation-duration: 4s;
  -webkit-animation-duration: 4s;
  animation-timing-function: linear;
  -o-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-iteration-count: 4;
  -o-animation-iteration-count: 4;
  -ms-animation-iteration-count: 4;
  -moz-animation-iteration-count: 4;
  -webkit-animation-iteration-count: 4;
  animation-play-state: running;
  -o-animation-play-state: running;
  -ms-animation-play-state: running;
  -moz-animation-play-state: running;
  -webkit-animation-play-state: running;
}
.toggle-project-wrapper {
  position: absolute;
  left: -30px;
  bottom: 15%;
  z-index: 10;
}
.toggle-project-wrapper-reverse {
  left: auto;
  right: -21px;
  bottom: 15%;
  z-index: 10;
}
.toggle-project-wrapper > div > div:first-child,
.toggle-project-wrapper-header > div > div:first-child,
.toggle-wrapper-details > div > div:first-child,
.toggle-wrapper-details-visible > div > div:first-child {
  border: 2px solid white;
}
.shadow-image {
  position: absolute;
  margin-top: -4px;
  height: 40px;
  width: 35px;
  margin-left: 4px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.reverse .shadow-image {
  transform: rotate(180deg);
  margin-left: -30px;
}
.project-content-header-img {
  visibility: hidden;
  position: absolute;
  display: flex;
  bottom: 36vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 10;
  height: 70%;
}
.hover-line {
  margin-top: 40px;
}
.project-content-header-img-visible {
  visibility: visible;
  display: flex;
  position: absolute !important;
  bottom: 36vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 10;
  width: 90% !important;
}
.looper2 {
  width: 105vh;
  position: absolute;
  bottom: -60vh;
  right: -7vw;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  visibility: hidden;
  display: none;
}

.looper3 {
  width: 45vh;
  right: 62vh;
  position: absolute;
  bottom: -26vh;
}

.show-ui-content .looper2 {
  visibility: visible;
  display: block;
}
.coffeeswitch {
  flex-direction: column;
}
.coffeeswitch > img {
  cursor: pointer;
  width: 60px;
  margin-top: 4px;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 17rem;
  height: 18rem;
  text-align: center;
  color: #fff;
  padding: 1rem;
  margin: 15rem 1rem 0;
}
.content-page {
  width: 100%;
  background-color: white;
  height: 600px;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

.card-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: #b0bec5;
}

.details {
  position: fixed;
  overflow-y: scroll;
  bottom: 0;
  top: 0px;
  left: 0;
  z-index: 100;
  pointer-events: none;

  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  /* max-width: 60rem; */
  width: 100vw;
}

.pointers-none {
  pointer-events: none;
}

.pointers-all {
  pointer-events: all;
}

.details-image {
  position: relative;
  z-index: 2;
  background-size: cover;
  background-position: center;
  background-color: #b0bec5;
  width: 100%;
  height: 90vh;
  /* height: 31rem; */
}

.project-pink-bg-ui {
  background-color: #ff4b6a;
}
.project-pink-bg-ui1 {
  background-color: #d8315b;
}
.project-purple-bg-ui {
  background-color: #4c6cf8;
}
.project-yellow-bg-ui {
  background-color: #fff056;
}
.project-yellow-bg-ui1 {
  background-color: #fff056;
  background-image: linear-gradient(36deg, #fff69a 16%, #ffe800 92%);
}
.project-peak-bg-ux {
  background-color: #b0bec5;
}
.reverse {
  flex-direction: row-reverse;
}
.reverse .project-peak {
  padding-right: 16.3%;
  width: 61%;
  padding-left: 0;
}
.reverse .project-description {
  padding-left: 16.3%;
}
/* .h1-home-project {
  opacity: 1;
} */
.contents-in {
  opacity: 0;
}
.contents-in-enter {
  opacity: 0;
  transform: translatey(20px);
}
.contents-in-enter-active {
  opacity: 1;
  transform: translatey(0px);
  transition: opacity 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44),
    transform 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44);
}
.contents-in-enter-done {
  transform: translatey(0px);
  opacity: 1;
}
.contents-in-exit {
  transform: translatey(0px);
  opacity: 1;
}
.contents-in-exit-active {
  opacity: 0;
  transform: translatey(20px);
  transition: opacity 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44),
    transform 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44);
}
.contents-in-exit-done {
  opacity: 0;
  transform: translatey(20px);
}

.h1-home-project-enter {
  opacity: 1;
  transform: translatex(0px);
}
.h1-home-project-enter-active {
  opacity: 0;
  transform: translatex(20px);
  transition: opacity 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44),
    transform 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44);
}
.h1-home-project-enter-done {
  transform: translatex(20px);
  opacity: 0;
  display: none;
}
.h1-home-project-exit {
  transform: translatex(20px);
  opacity: 0;
}
.h1-home-project-exit-active {
  opacity: 1;
  transform: translatex(0);
  transition: opacity 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44),
    transform 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44);
}
.h1-home-project-exit-done {
  opacity: 1;
  transform: translatex(0);
}

.p-home-project-enter {
  opacity: 1;
}
.p-home-project-enter-active {
  opacity: 0;
  transform: translatex(20px);
  transition: opacity 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44),
    transform 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44);
  transition-delay: 0.1s;
}
.p-home-project-enter-done {
  transform: translatex(20px);
  opacity: 0;
}
.p-home-project-exit {
  transform: translatex(20px);
  opacity: 0;
}
.p-home-project-exit-active {
  opacity: 1;
  transform: translatex(0);
  transition: opacity 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44),
    transform 0.3s cubic-bezier(0.22, -0.3, 0.68, 1.44);
  transition-delay: 0.05s;
}
.p-home-project-exit-done {
  opacity: 1;
  transform: translatex(0);
}

/* CONTENT */
.toggle-wrapper-details {
  visibility: hidden;
  display: flex;
  position: fixed;
  right: 5.4%;
  bottom: calc(100vw * 0.054);
}
.toggle-wrapper-details-visible {
  position: fixed;
  right: 5.4%;
  display: flex;
  bottom: calc(100vw * 0.054);
  visibility: visible;
  z-index: 100;
}
.content-section-img {
  width: 100%;
  position: relative;
}
.close-details {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  top: 4vw;
  width: 30px;
  right: 4.6%;
}
.content-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: white;
  padding: 170px 17%;
}
.content-section > div:first-child {
  margin-bottom: 35px;
  align-self: flex-start;
}

.content-section .text-columns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 100px;
}
.content-section .text-columns p {
  text-align: left;
  font-size: 0.893em;
  line-height: 2;
  font-weight: 600;
}
.content-section .text-columns p:first-child {
  margin-right: 36px;
}

.box-1 {
  padding: 28px;
  position: relative;
  width: 522px;
}
.box-1 p {
  color: #ffffff;
}
.box-1.ux {
  background-color: #b0bec5;
}
.box-1.ui {
  background-color: #ff4b6a;
}
.box-1.ux h3 {
  background-color: #cfd8dc;
  color: #535b5f;
}
.box-1 h3 {
  background-color: white;
  position: absolute;
  top: 1.3em;
  left: -2em;
  font-size: 2.8em;
  margin: 0;
  padding: 0.3em 1.2em;
  text-transform: uppercase;
}

.content-image-ui {
  width: 100%;
  /* position: absolute;
  right: 0; */
  display: none;
}
.content-image-ux {
  width: 100%;
  display: block;
  /* position: absolute;
  right: 0; */
}
.show-ui-content .content-image-ui {
  display: block;
}
.show-ui-content .content-image-ui.try {
  display: none;
}
.show-ui-try-content .content-image-ui.try {
  display: block;
}
.show-ui-try-content .content-image-ui {
  display: none;
}
.show-ui-content .content-image-ux {
  display: none;
}
/* .show-ui-try-content .content-image-ux {
  display: none;
} */
.pixel {
  height: 1px;
  width: 1px;
}
.tryBg {
  background-image: linear-gradient(81deg, #dc7c3d -3%, #ffceae 97%);
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes wiggle {
  2% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }

  4% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }

  6% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }

  8% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }

  10% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }

  12% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }

  14% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }

  16% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }

  18% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }

  20% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-20vw);
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
